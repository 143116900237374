import './index.scss'
import ClearDiv from '@/components/clearDiv'
import novel1 from '@/static/images/novel1.png'
import { Image, Button } from 'react-bootstrap'
import FormItem from '@/components/formItem'
import FormInput from '@/components/formInput'
import FormTextArea from '@/components/formTextArea'
import ImageUploader from '@/components/imageUploader'
import { useEffect, useState,useRef } from 'react'
import { createBook, getBookById,updateBook,uploadFile } from '@/data'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {createAlert} from '@/components/dzAlert'
import { useDispatch } from 'react-redux'
import { setLoadingCoverStatus } from '@/store'
function StoryInfo() {
  const jumpTo = useNavigate()
  const coverRef = useRef()
  const coverImageRef = useRef()
  const titleRef = useRef()
  const descriptionRef = useRef()
  const authorRef = useRef()
  const profileRef = useRef()
  const emailRef = useRef()
  const dispatch = useDispatch()
  const [bookId, setBookId] = useState()
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [author, setAuthor] = useState()
  const [profile, setProfile] = useState()
  const [opensea, setOpensea] = useState()
  const [email, setEmail] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [curUploadFile,setCurUploadFile] = useState()
  const [curImageUrl,setCurImageUrl] = useState()
  const [nftUrl, setNftUrl] = useState()
  const [search] = useSearchParams()
  useEffect(()=>{
    if(curUploadFile){
      submitBook()
    }
  },[curUploadFile])
  async function submit(){
    console.log('curUploadFile:',curUploadFile)
    console.log('curImageUrl:',curImageUrl)
    console.log('imageUrl:',imageUrl)
    if(!validBookForm()) return;
    if(!curUploadFile && (curImageUrl === imageUrl)){
      submitBook()
    }
    else{
      const CoverUrl = await coverImageRef.current.submitUpload()
      setCurImageUrl(CoverUrl)
      setCurUploadFile(CoverUrl)
    }
  }
  async function submitBook() {
    const bookInfo = {
      "id": bookId,
      "author_name": author,
      "description": description,
      "image_url": curImageUrl,
      "title": title,
      "author_profile": profile,
      "email": email,
      "opensea": opensea,
      "nft_url": nftUrl,
    }
    console.log(bookInfo)
    dispatch(setLoadingCoverStatus(true))
    let res = bookId?await updateBook(bookInfo):await createBook(bookInfo)
    dispatch(setLoadingCoverStatus(false))
    if (res?.code === 200) {
      jumpTo('/mystory', { replace: true })
    }
  }
  function validBookForm(){
    if(!imageUrl){
      createAlert({type:'danger',content:'Book Cover is necessary'})
      window.scroll({ top: coverRef.current.offsetTop, behavior: 'smooth' })
      return false
    }
    if(!title){
      createAlert({type:'danger',content:'Title is necessary'})
      window.scroll({ top: titleRef.current.offsetTop, behavior: 'smooth' })
      titleRef.current.querySelector('input').focus()
      return false
    }
    if(!description){
      createAlert({type:'danger',content:'Description is necessary'})
      window.scroll({ top: descriptionRef.current.offsetTop, behavior: 'smooth' })
      descriptionRef.current.querySelector('textarea').focus()
      return false
    }
    if(!author){
      createAlert({type:'danger',content:'Author Name is necessary'})
      window.scroll({ top: authorRef.current.offsetTop, behavior: 'smooth' })
      authorRef.current.querySelector('input').focus()
      return false
    }
    if(!profile){
      createAlert({type:'danger',content:'Author Profile is necessary'})
      window.scroll({ top: profileRef.current.offsetTop, behavior: 'smooth' })
      profileRef.current.querySelector('textarea').focus()
      return false
    }
    if(!email||!/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/.test(email)){
      console.log(emailRef.current)
      createAlert({type:'danger',content:'Email is invalid'})
      window.scroll({ top: emailRef.current.offsetTop, behavior: 'smooth' })
      emailRef.current.querySelector('input').focus()
      return false
    }
    return true
  }
  async function loadBookInfo(bookId) {
    if (!bookId) return;
    let params = {
      "author": "",
      "id": +bookId,
      "limit": 0,
      "offset": 0,
      "title": ""
    }
    dispatch(setLoadingCoverStatus(true))
    let res = await getBookById(params)
    dispatch(setLoadingCoverStatus(false))
    if (res?.code == 200) {
      const { author_name, description, image_url, title, email, author_profile } = res.data
      console.log('title:::::',title)
      setTitle(title)
      setDescription(description)
      setImageUrl(image_url)
      setAuthor(author_name)
      setEmail(email)
      setProfile(author_profile)
      setCurImageUrl(image_url)
      // setCurUploadFile(image_url)
    }
  }
  useEffect(() => {
    setBookId(+search.get('bookId'))
  }, [])
  useEffect(() => {
    loadBookInfo(bookId)
  }, [bookId])
  return (
    <div className='story-info container'>
      <ClearDiv>
        <div className='story-tips'>Tips: Platform only supports novels written in Chinese and English. We will not review other voice messages for the time being, thank you for your attention.</div>
        <div className='flex-row-align-start story-main'>
          <div className='story-cover' ref={coverRef}>
            <div className='cover-upload'>
              <ImageUploader imageUrl={imageUrl} load={e => setImageUrl(e)} onRef={coverImageRef} />
            </div>
            <div className='images-tips'>* Press to upload book cover. 562 * 688px, no more than 5M, .JPG, .JPEG, .PNG</div>
          </div>
          <div className='story-content'>
            <FormItem label="Title:" required itemRef={titleRef}>
              <FormInput placeholder="Less than 50 words" maxLength={50} defaultValue={title} onInput={e => setTitle(e)} />
            </FormItem>
            <FormItem label="Description:" required itemRef={descriptionRef}>
              <FormTextArea placeholder="Less than 3000 words" defaultValue={description} onInput={e => setDescription(e)} maxLength={3000}></FormTextArea>
            </FormItem>
            <FormItem label="Author Name:" required itemRef={authorRef}>
              <FormInput placeholder="Less than 50 words" defaultValue={author} onInput={e => setAuthor(e)} maxLength={50} />
            </FormItem>
            <FormItem label="Author Profile:" required itemRef={profileRef}>
              <FormTextArea placeholder="Less than 3000 words" defaultValue={profile} onInput={e => setProfile(e)} maxLength={3000}></FormTextArea>
            </FormItem>
            <FormItem label="Income project:"  >
              <div>
                <ImageUploader imageUrl={nftUrl} load={e => setNftUrl(e)} />
              </div>
            </FormItem>
            <FormItem>
              <FormInput placeholder="Link of opensea" defaultValue={opensea} onInput={e => setOpensea(e)} maxLength={3000} />
            </FormItem>
            <FormItem>
              <div className='f-12 p-d-l-10 m-g-b-30'>NFT link on directory popup window. 379*112px, no more than 5M, .JPG, .JPEG, .PNG</div>
            </FormItem>
            <FormItem label="Email:" required itemRef={emailRef}>
              <FormInput placeholder="Less than 50 words" defaultValue={email} onInput={e => setEmail(e)} maxLength={50}/>
            </FormItem>
            <FormItem>
              <div className='f-12 p-d-l-10 m-g-b-30'>In order to ensure the review progress of the novel and promote it to the market quickly, please submit the email of the contact person of the book, and we will timely communicate with you in the email.</div>
            </FormItem>
            <FormItem>
              <div className='p-d-l-10 flex-row m-g-b-30 flex-wrap btns-group'>
                <Button variant="primary" onClick={e => submit()}>{bookId?'Update':'Create'}</Button>
                <Button variant="outline-secondary" onClick={e => jumpTo('/mystory', { replace: true })}>Cancel</Button>
              </div>
            </FormItem>
          </div>
        </div>
      </ClearDiv>
    </div>
  )
}
export default StoryInfo;