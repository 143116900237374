import './index.scss'
import { Image, ToastContainer, Toast } from 'react-bootstrap'
import chapterOpen from '@/static/images/chapter-open.png'
import chapterClose from '@/static/images/chapter-close.png'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {getBookById} from '@/data'
import coverErr from '@/static/images/coverErr.png'
import rightArr from '@/static/images/right-arr.png'
import catwoman from '@/static/images/catwoman.png'
import { useNavigate } from 'react-router-dom'

function NovelChapterPop({ volumeList = [],bookId = '', chapterClick }) {
  const winWidth = window.innerWidth
  const { address } = useSelector(state => state.home)
  const [chapterListShow, setChapterListShow] = useState(false)
  const [search] = useSearchParams()
  const jumpTo = useNavigate()
  const [bookInfo,setBookInfo] = useState();
  const [readBookChapter, setReadBookChapter] = useState({})
  const [position, setPosition] = useState(winWidth > 680?'top-end':"top-center")
  const toggleChapterListPop = () => {
    setChapterListShow(!chapterListShow)
  }
  useEffect(() => {
    window.onresize = e => {
      const winWidth = window.innerWidth
      setPosition(winWidth > 680 ? 'top-end' : 'top-center')
    }
  }, [])
  
  useEffect(() => {
    loadBook(bookId)
    // loadBookDetailList(bookId)
  }, [bookId])
  async function loadBook(bookId) {
    if (!bookId) return;
    let params = {
      "author": "",
      "id": +bookId,
      "limit": 0,
      "offset": 0,
      "title": ""
    }
    const res = await getBookById(params)
    if (res?.code === 200) {
      setBookInfo(res.data)
    }
  }
  return (
    <>
      <Image className='chapter-btn pointer' src={chapterListShow ? chapterClose : chapterOpen} onClick={e => toggleChapterListPop()} width={50} />
      <ToastContainer position={position} className='chapter-pop'>
        <Toast bg="light" show={chapterListShow}>
          <Toast.Body>
            <div className="book-main">
              <div className="book-header">
                <div className="catwoman">
                    {/* <Image src={catwoman} width={'100%'}></Image> */}
                </div>
                <div className="book-info flex-row-space-between pointer m-g-b-20 m-g-l-20 m-g-r-20" onClick={e=>jumpTo(`/book?id=${bookId}`)}>
                  <div className='flex-row-align-start'>
                    <img className='m-g-r-8' src={bookInfo?.image_url||coverErr} width={109} onError={e=>e.target.src=coverErr} ></img>
                    <div className="book-name m-g-r-8">
                      <div className='text-line-2' title={bookInfo?.title}>{bookInfo?.title}</div>
                      <div className="book-author text-line-1" title={bookInfo?.author_name}>{`By ${bookInfo?.author_name}`}</div>
                    </div>
                  </div>
                  <div>
                    <Image src={rightArr} width={8}></Image>
                  </div>
                </div>
              </div>
              <div className='book-detail'>
                {volumeList.map((vol, idx) => {
                  return (
                    <div className='volume-container p-d-b-20' key={`vol-${idx}`}>
                      <div className="volume-title text-line-1 border-top p-d-t-20" title={vol.name}>volume {vol?.volume}: {vol.name}</div>
                      <div className="chapter-container">
                        {vol.list?.map((chap, i) => {
                          return <div title={chap.chapter_title} className={`chapter-item text-line-1 pointer ${chap.read ? 'read' : ''}`} key={`chapter-${i}`} onClick={e => chapterClick?.(chap)}>chapter {chap.chapter}: {chap?.chapter_title}</div>
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}
export default NovelChapterPop;