import './index.scss';
import { Image, Button } from 'react-bootstrap';
import catwoman from '@/static/images/catwoman-avatar.png'
import boat from '@/static/images/boat.png'
function NftDesc() {
  return (
    <div className="nft-desc flex-row-space-around">
      <div className="catwoman">
        <Image src={catwoman} className='catwoman-icon'></Image>
      </div>
      <div className="nft-content">
        <div className="title">Taking NFT from asset to property for commercialize</div>
        <div className="desc">Novel content will be created to NFT as IP, authorize the merchants to make goods to earn authorization fees, and at the same time, these NFTs will become the SBT in the ecosystem of DZ Labs</div>
        <div className="btns">
          <Button onClick={e=>window.open('https://opensea.io/collection/catwomanstar','_blank')} variant="outline-secondary" className='opensea text-line-1 writer  p-d-l-30 p-d-r-30 p-d-t-12 p-d-b-12'>
            <Image src={boat} width={30} className='m-g-r-10'></Image>
            Collect From Opensea </Button>
        </div>
      </div>
    </div>
  )
}
export default NftDesc;