import './index.scss'
import ClearDiv from '@/components/clearDiv';
import { Button } from 'react-bootstrap';
import StoryCard from './components/storyCard';
import { useNavigate } from 'react-router-dom';
import { getBookList,getBookListByUser,updateBook } from '@/data';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoadingCoverStatus } from '@/store';
function MyStory() {
    const jumpTo = useNavigate()
    const dispatch = useDispatch()
    const [bookList, setBookList] = useState([])
    useEffect(() => {
        updateBookList()
    }, [])
    async function updateBookList() {
        dispatch(setLoadingCoverStatus(true))
        const res = await getBookListByUser({
            "limit": 200,
            "offset": 0,
            "title": ""
        })
        dispatch(setLoadingCoverStatus(false))
        if (res?.data) {
            setBookList(res.data || [])
        }
    }
    
  async function updateBookInfo(bookInfo){
    bookInfo.status = 3
    const res = await updateBook(bookInfo)
    updateBookList()
  }
    return (
        <div className='container my-story'>
            <ClearDiv>
                <div className='my-story-container'>

                    <div className='my-story-title'>My Stories</div>
                    <div className='tips'>Tips: After novel is approved, platform will contact publisher to sign a contract. Then novels cannot be modified and users can start reading.</div>
                    <div className='btns-group flex-row'>
                        <Button variant="outline-secondary" className='about-btn'>About writers</Button>
                        <Button className='create-btn' onClick={e => jumpTo('/storyinfo')}>Create+</Button>
                    </div>
                    <div className='my-story-list flex-row-space-between flex-row-wrap'>
                        {bookList.map(book =>
                            <StoryCard
                                book={book}
                                key={book.id}
                                onEditClick={e => jumpTo(`/storyinfo?bookId=${book.id}`)}
                                onChapterClick={e => jumpTo(`/volume?bookId=${book.id}`)}
                                onEndClick={e=>updateBookInfo(book)}
                            />)
                        }
                    </div>
                </div>
            </ClearDiv>
        </div>
    )
}
export default MyStory;