import './index.scss'
import TitleDiv from '@/components/titleDiv'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core'
import { useEffect, useState } from 'react'
SwiperCore.use([Autoplay, Navigation, Pagination])
function NFTIcoins() {
  const winWidth = window.innerWidth
  const num = Math.floor(winWidth / 140) > 8 ? 8 : Math.floor(winWidth / 140)
  const [size, setSize] = useState(num)
  useEffect(() => {
    window.onresize = e => {
      const winWidth = window.innerWidth
      const num = Math.floor(winWidth / 140) > 8 ? 8 : Math.floor(winWidth / 140)
      setSize(num)
    }
  })
  return (
    <div className='nft-icoins'>
      {/* <TitleDiv>NFTs are selling on Opensea</TitleDiv> */}
      <div className='nft-list-container'>
        <div className='nft-list' >
          <Swiper spaceBetween={10}
            slidesPerView={size}
            loop
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            onSlideChange={() => false}
            onSwiper={(swiper) => false}>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 0}px 0px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 1}px 0px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 2}px 0px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 3}px 0px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 4}px 0px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 5}px 0px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 6}px 0px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 7}px 0px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 8}px 0px` }}></div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='nft-list' >
          <Swiper spaceBetween={10}
            loop
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            slidesPerView={size}
            onSlideChange={() => false}
            onSwiper={(swiper) => false}>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 0}px -146px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 1}px -146px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 2}px -146px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 3}px -146px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 4}px -146px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 5}px -146px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 6}px -146px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 7}px -146px` }}></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='nft-item' style={{ backgroundPosition: `${-145 * 8}px -146px` }}></div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}
export default NFTIcoins