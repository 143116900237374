import LearnMore from "./components/learnMore";
import AppDownload from "./components/appDownload";
import OnePassCard from "./components/onePassCard";
import BussinessModel from "./components/bussinessModel";
import NftDesc from "./components/nftDesc";
import NFTIcoins from "./components/nftIcons";
import CoinDesc from './components/coinDesc'
import Roadmap from "./components/roadmap";
import Questions from "./components/questions";
import SupportedBy from "./components/supportedBy";
import './index.scss'
function Home(){
    return (
        <div className="container home">
            <LearnMore></LearnMore>
            <AppDownload></AppDownload>
            {/* <OnePassCard></OnePassCard> */}
            {/* <div className="flex-row-space-between card-container m-g-b-80">
                <OnePassCard></OnePassCard>
                <BussinessModel></BussinessModel>
            </div> */}
            <NftDesc></NftDesc>
            <NFTIcoins></NFTIcoins>
            <CoinDesc></CoinDesc>
            {/* <Roadmap></Roadmap> */}
            <Questions></Questions>
            <SupportedBy></SupportedBy>
        </div>
    )
}
export default  Home;