import './index.scss'
import { Image } from 'react-bootstrap'
import ethIcon from '@/static/images/SB-eth.png'
import metamaskIcon from '@/static/images/SB-mt.png'
import mirrorIcon from '@/static/images/SB-mr.png'
import openseaIcon from '@/static/images/SB-os.png'
import mediumIcon from '@/static/images/SB-med.png'

function SupportedBy(){
    return (
        <div className='supported-by'>
            <div className='supported-title heavy-title'>Supported by</div>
            <div className='flex-row-wrap supported-list'>
                <div className='flex-row-center supported-item'>
                    <Image src={ethIcon} width={40}></Image>
                    <div className='item-text'>Ethereum</div>
                </div>
                <div className='flex-row-center supported-item'>
                    <Image src={metamaskIcon} width={40}></Image>
                    <div className='item-text'>Metamask</div>
                </div>
                <div className='flex-row-center supported-item'>
                    <Image src={openseaIcon} width={40}></Image>
                    <div className='item-text'>Opensea</div>
                </div>
                <div className='flex-row-center supported-item'>
                    <Image src={mirrorIcon} width={40}></Image>
                    <div className='item-text'>Mirror</div>
                </div>
                <div className='flex-row-center supported-item'>
                    <Image src={mediumIcon} width={'80%'}></Image>
                </div>
            </div>
        </div>
    )
}
export default SupportedBy