import { useEffect,useRef } from 'react';
import './index.scss'
function FormInput(props) {
  const ref = useRef();
  useEffect(()=>{
    console.log(props)
    ref.current.value = props.defaultValue||''
  },[])
  useEffect(()=>{
    ref.current.value = props.defaultValue||''
  },[props])
    return (
        <div className='dz-input' style={props.style}>
            <input ref={ref} style={{color:props.dark?'#222':'#fff'}} defaultValue={props.defaultValue} onInput={e=>props.onInput(e.target.value)} readOnly={props.readOnly} disabled={props.disabled} type={props.type} maxLength={props.maxLength} placeholder={props.placeholder} minLength={props.minLength}/>
        </div>
    )
}
export default FormInput;