import './index.scss'
import ClearDiv from '@/components/clearDiv'
import { Image } from 'react-bootstrap'
import buM from '@/static/images/buM.png'
function BussinessModel(){
    return (
        <div className='bussiness'>
            <ClearDiv  className='bussiness'>
                <Image src={buM} width={499}  className="bussiness-card m-g-t-20"></Image>
                <div className='bussiness-title heavy-title'>Bussiness Model</div>
                <div className='bussiness-text-title heavy-title'>Web3 Structure</div>
                <div className='bussiness-text-content f-15'>The organizational structure of DZ Labs is composed of three parts:DAO, Token and Treasury. The goal of DAO is to drive the organization to realize business, and the economy to give back to DAO members is Token. The money earned through the novel signed by DAO will be put in the Treasury, which will be used as the operation and reward of the community.</div>
                <div className='bussiness-text-title heavy-title'>Profit Sources</div>
                <div className='bussiness-text-content f-15'>The novel will be developed into GameFi, NFT, movie, and IP co-branded with any brand.</div>
            </ClearDiv>
        </div>
    )
}
export default BussinessModel