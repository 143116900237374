import Button from 'react-bootstrap/Button';
import { Image } from 'react-bootstrap';
import stepList from '@/static/images/stepList.png';
import './index.scss'
function LearnMore() {
    return (
        <div className='learn-more'>
            <div className='learn-content'>
                <div>16dao.xyz is a novel DeFi organization</div>
                <div>that uses Web3 to operate.</div>
                <div>Motivated by Token $16DAO</div>
            </div>
            <Image src={stepList} width={500} className='step-list'></Image>
            {/* <Button variant="primary" className='learn-more-btn'>Learn more →</Button> */}
            <Button variant="outline-secondary" className='learn-more-btn text-line-1 writer' onClick={e=>window.open('https://medium.com/@16DAO/dz-labs-whitepaper-financing-d9f475a74d66','_blank')}>Learn more</Button>
        </div>
    )
}
export default LearnMore;