import './index.scss'
import { Image ,Button } from 'react-bootstrap'
import googleplay from '@/static/images/googleplay.png'
import android from '@/static/images/android.png'
import app from '@/static/images/app.png'
import novel1 from '@/static/images/novel1.png'
import novel5 from '@/static/images/novel5.png'
import novel3 from '@/static/images/novel3.png'
import novel4 from '@/static/images/novel4.png'
function AppDownload() {
  return (
    <div className="app-download flex-row-space-around">
      <div className="content">
        <div className="title">
          Users can read for $16DAO rewards on their phones
        </div>
        <div className="desc">The purpose of reading for token rewards is because every reader's feelings are worth remembering. Come on, read anytime, anywhere, whether on your computer or on your phone, and talk to your soul anytime, anywhere.</div>
        <div className="btns flex-row">
          <Button variant="outline-secondary" onClick={e=>window.open('https://play.google.com/store/apps/details?id=xyz.dao16.flutter_16dao&pcampaignid=web_share','_blank')} className='google m-g-r-20 text-line-1 writer p-d-l-30 p-d-r-30 p-d-t-12 p-d-b-12'>
            <Image src={googleplay} width={22}></Image>Google Play
          </Button>
          <Button variant="outline-secondary" onClick={e=>window.open('/16dao.apk','_blank')} className='android text-line-1 writer  p-d-l-30 p-d-r-30 p-d-t-12 p-d-b-12'>
            <Image src={android} width={22}></Image>Android APK
          </Button>
        </div>
        <div className="novel-list flex-row">
          <Image src={novel1} width={120}></Image>
          <Image src={novel3} width={120}></Image>
          <Image src={novel4} width={120}></Image>
          <Image src={novel5} width={120}></Image>
        </div>
      </div>
      <div className="app-cover">
        <Image src={app} className='app' ></Image>
      </div>
    </div>
  )
}
export default AppDownload