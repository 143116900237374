import './index.scss'
import { Image, Button } from 'react-bootstrap';
import novel1 from '@/static/images/novel1.png'
import NovelChapterPop from '@/components/novelChapterPop';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getBookById,getBookDetailList } from '@/data';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import coverErr from '@/static/images/coverErr.png'
import { useDispatch } from 'react-redux';
import { setAddress, setWarnContent } from '@/store';
import { groupBy } from '@/utils';
function Book() {
  const [search] = useSearchParams()
  const dispatch = useDispatch();
  const {address} = useSelector(state=>state.home)
  const [bookId, setBookId] = useState()
  const [bookInfo, setBookInfo] = useState({})
  const [volumeList,setVolumeList] = useState([])
  let jumpTo = useNavigate()
  useEffect(() => {
    setBookId(search.get('id'))
  })
  useEffect(() => {
    loadBook(bookId)
    // loadBookDetailList(bookId)
  }, [bookId])
  async function loadBook(bookId) {
    if (!bookId) return;
    let params = {
      "author": "",
      "id": +bookId,
      "limit": 0,
      "offset": 0,
      "title": ""
    }
    const res = await getBookById(params)
    if (res?.code === 200) {
      setBookInfo(res.data)
    }
  }
  function readBook(){
    if (!Cookies.get('token')) {
      dispatch(setWarnContent('Please connect to your MetaMask Wallet!'))
      dispatch(setAddress(''))
      return;
    }
    jumpTo(`/read?id=${bookId}&title=${bookInfo?.title}`)
  }
  // async function loadBookDetailList(bookId){
  //   if(!bookId) return;
  //   const res = await getBookDetailList({
  //     "book_id": +bookId,
  //     "limit": 10,
  //     "offset": 0
  //   })
  //   if (res?.code === 200) {
  //     const groupBooksRes = groupBy(res.data, 'volume', 'volume_title')
  //     setVolumeList(groupBooksRes)
  //   }
  // }
  return (
    <>
      <div className='book container'>
        {/* <Image src={bookInfo?.image_url} width={170} /> */}
        <img src={bookInfo?.image_url||coverErr} width={170} onError={e=>e.target.src=coverErr} ></img>
        <div className='book-name'>{bookInfo?.title}</div>
        <div className='book-description'>
          {bookInfo?.description}
        </div>
        <div className='book-author flex-row'>
          <div className='author-tag'>Author :</div>
          <div className='author-name'>{bookInfo?.author_name}</div>
        </div>
        <div className='author-info'>{bookInfo?.author_profile}</div>
        <div className='read-cost flex-row-center'>
          {/* <div className='cost-info'>
            <div className='info-item flex-row-space-between'>
              <div className='info-tag'>ARWEAVE TX</div>
              <div className='text-line-1'>R6Lezh9CLj4B5Qf6QxDN_W-gAWHPNpiWhYrPfFkGc88</div>
            </div>
            <div className='info-item flex-row-space-between'>
              <div className='info-tag'>OPTIMISM ADDRESS</div>
              <div className='text-line-1'>{address}</div>
            </div>
          </div> */}
          <Button onClick={e => readBook()}>Read Now</Button>
        </div>
      </div>
      {/* <NovelChapterPop volumeList={volumeList} chapterClick={e=>jumpTo(`/read?id=${e.book_id}&volume=${e.volume}&chapter=${e.chapter}`)}></NovelChapterPop> */}
    </>
  )
}
export default Book;