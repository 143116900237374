import './index.scss'
import TitleDiv from '@/components/titleDiv'
import QuestionDiv from './components/questionDiv'
function Questions() {
    return (
        <div>
            <TitleDiv>MARKET OPPORTUNITIES AND BUSINESS PROSPECTS</TitleDiv>
            {/* <QuestionDiv title='How is the project going?' content="Among the two novels signed by DZ Labs in Q2 2022, the first novel, 《核桃记》, has been completed, with a total of 301,000 words, and its electronic version has been uploaded to ByteDance's fanqie novel platform. The female protagonist in the novel holds a painting named Catwomen, for which DZ Labs has created NFT according to the commercial plan. The first 6 NFT have been put on Opensea. The novel is in contact with presss and plans to publish physical books, which has strengthened DZ Labs 'continued influence in the market."></QuestionDiv> */}
            <QuestionDiv title='How big size of the market?' content="In October 2021, the Chinese Writers' Association released the report on the Development of International Dissemination of Chinese Internet Literature in Wuzhen, Zhejiang Province. The report shows that more than 10,000 works of Chinese Internet literature have been distributed overseas, with more than 100 million users reading through foreign websites and APPs, covering most countries and regions in the world."></QuestionDiv>
            <QuestionDiv title="What's Opportunities DZ Labs got?" content="The number of completed novels in the Chinese market alone exceeds 24 million. While the number of books is growing, there is no organization to commercialize them and no such service in the market. DZ Labs has no clear competitors in this field and benefits from the development of web3. DZ Labs adopts the DAO method, which will significantly improve the efficiency of novel operations and help more writers monetize."></QuestionDiv>
            <QuestionDiv title='What problem DZ Labs will solve?' content={`China's online literature consumption users exceed 500 million people, and overseas consumption of Chinese online literature users exceeds 100 million. Given users' massive demand, more than 96.5% of writers have no income. Their novels have barely had chances to be adapted into movies and receive commercial payments. Online literature platform unfair terms make writers lose the copyright of their works. The platform monopolizes novel copyright for at least five years, at most 50 years after the author's death.
<br />
<br />
DZ Labs returns the choice of online literary works to 600 million users, who can choose what to read based on their preferences rather than relying on the platform recommendations that come from their streaming algorithm.
<br />
<br />
DZ Labs returns the copyright to the writers, allowing them to become DAO members and letting them drive the DAO organization to grow.`}></QuestionDiv>
        </div>
    )
}
export default Questions