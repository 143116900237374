import './index.scss'
import coin from '@/static/images/coin.gif'
import { Image, Button } from 'react-bootstrap'
function CoinDesc() {
  return (
    <div className="coin-desc flex-row-space-around">
      <div className="coin-content">
        <div className="title">$16DAO</div>
        <div className="info flex-row">
          {/* <div className="info-item">
            Blockchain Network: <span>Ethereum</span>
          </div>
          | */}
          <div className="info-item">
            Total Supply: <span>16,000,000</span>
          </div>
        </div>
        <div className="process flex-row">
          <div className="process-item">
            Community: 60%
          </div>  |
          <div className="process-item">
            Start contributor: 20%
          </div>  |
          <div className="process-item">
            Foundation: 13%
          </div>  |
          <div className="process-item">
            Founder team: 7%
          </div>
        </div>
        <div className="process-bar flex-row">
          <div className="community"></div>
          <div className="contributor"></div>
          <div className="foundation"></div>
          <div className="team"></div>
        </div>
        <div className="btns flex-row">
          <Button variant="outline-secondary" className='coinbtn text-line-1 writer  p-d-l-15 p-d-r-15 p-d-t-6 p-d-b-6 m-g-r-10'>Number one position</Button>
          <Button variant="outline-secondary" className='coinbtn text-line-1 writer  p-d-l-15 p-d-r-15 p-d-t-6 p-d-b-6 m-g-r-10'>Multi Token airdrop</Button>
          <Button variant="outline-secondary" className='coinbtn text-line-1 writer  p-d-l-15 p-d-r-15 p-d-t-6 p-d-b-6 m-g-r-10'>Repurchase income</Button>
        </div>
        <div className="desc">16dao.xyz is one product of DZ Labs. DZ comes from the first letter of Decentralization Zone. Our vision is to build a decentralized organization so that individual wisdom and value will be enlarged. So we designed $16DAO Token, which will be the blood of DZ Labs. We will gradually open the participation rights of the organization to token holders. The token is equivalent to their owning shares of DZ Labs, enjoying voting rights and dividend rights. They're going to pro rata back to the token holder.</div>
      </div>
      <div className="coin text-center">
        <Image src={coin} width={360}></Image>
      </div>
    </div>
  )
}
export default CoinDesc;